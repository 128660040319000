export class Constant {
  // localhost_url = 'http://localhost:8000/';
  //localhost_url = 'https://dev.moval.techkrate.in/backend/';
  localhost_url = 'https://moval.techkrate.com/backend/';

  pdfURL = this.localhost_url + 'viewpdf.php?URL=';

  showLoader() {
    (<HTMLInputElement>document.getElementById('loader')).style.display =
      'block';
  }

  hideLoader() {
    (<HTMLInputElement>document.getElementById('loader')).style.display =
      'none';
  }

  isValidEmail(email: string) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  }

  isSuperAdmin() {
    if (localStorage.getItem('IsSuperAdmin') == 'true') {
      return true;
    }
    return false;
  }

  dateVisibility() {}
}
